var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("All Product Categories")])]),_c('CCardBody',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover table-sm"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Brand")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Description")]),(
                    _vm.can('product_category_read') ||
                    _vm.can('product_category_update') ||
                    _vm.can('product_category_delete')
                  )?_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Action ")]):_vm._e()])]),(_vm.isLoading)?_c('tbody',{staticClass:"text-center pt-5"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_c('CSpinner',{attrs:{"color":"primary","size":"lg"}})],1)])]):_c('tbody',_vm._l((_vm.product_categories),function(product_category){return _c('tr',{key:product_category.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(product_category.name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(product_category.brand.name))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(product_category.description))]),(
                    _vm.can('product_category_read') ||
                    _vm.can('product_category_update') ||
                    _vm.can('product_category_delete')
                  )?_c('td',{staticClass:"text-center"},[_c('CButtonGroup',{attrs:{"size":"sm"}},[(_vm.can('product_category_read'))?_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showProductCategory(_vm.showModal, product_category)}}},[_vm._v(" View")]):_vm._e(),(_vm.can('product_category_update'))?_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.editProductCategory(product_category.id)}}},[_vm._v(" Edit")]):_vm._e(),(_vm.can('product_category_delete'))?_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteProductCategory(_vm.deleteModal, product_category)}}},[_vm._v("Delete")]):_vm._e()],1)],1):_vm._e()])}),0)]),(!_vm.isLoading)?_c('CPagination',{attrs:{"activePage":_vm.pagination.current,"pages":_vm.pagination.last,"align":"center"},on:{"update:activePage":[function($event){return _vm.$set(_vm.pagination, "current", $event)},_vm.getResults],"update:active-page":function($event){return _vm.$set(_vm.pagination, "current", $event)}}}):_vm._e()],1)])],1)],1),_c('ProductCategoryView'),_c('ProductCategoryDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }