<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Product Categories</strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Name</th>
                  <!-- <th scope="col" class="text-left">Product Group</th> -->
                  <th scope="col" class="text-left">Brand</th>
                  <th scope="col" class="text-left">Description</th>

                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('product_category_read') ||
                      can('product_category_update') ||
                      can('product_category_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr
                  v-for="product_category in product_categories"
                  :key="product_category.id"
                >
                  <td class="text-left">
                    {{ product_category.name }}
                  </td>
                  <!-- <td class="text-left">{{ product_category.product_group.name }}</td> -->
                  <td class="text-left">{{ product_category.brand.name }}</td>
                  <td class="text-left">{{ product_category.description }}</td>
                  <td
                    class="text-center"
                    v-if="
                      can('product_category_read') ||
                      can('product_category_update') ||
                      can('product_category_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="
                          showProductCategory(showModal, product_category)
                        "
                        v-if="can('product_category_read')"
                      >
                        View</CButton
                      >

                      <CButton
                        color="info"
                        @click="editProductCategory(product_category.id)"
                        v-if="can('product_category_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="
                          deleteProductCategory(deleteModal, product_category)
                        "
                        color="danger"
                        v-if="can('product_category_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <ProductCategoryView />
    <ProductCategoryDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const ProductCategoryView = () =>
  import("@/views/ProductCategory/ProductCategoryView");
const ProductCategoryDelete = () =>
  import("@/views/ProductCategory/ProductCategoryDelete");

export default {
  name: "ProductCategories",
  components: {
    ProductCategoryDelete,
    ProductCategoryView,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("product_categories/getProductCategories");
  },
  computed: {
    ...mapState({
       pagination: (state) => state.product_categories.pagination,
      product_categories: (state) =>
        state.product_categories.product_categories,
      isLoading: (state) => state.product_categories.isLoading,
      deleteModal: (state) => state.product_categories.deleteModal,
      showModal: (state) => state.product_categories.showModal,
    }),
  },
  methods: {
      getResults(page = 1) {
      this.$store.dispatch("product_categories/getProductCategories", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    showProductCategory(bool, product_category) {
      this.$store.commit("product_categories/SHOW_MODAL", {
        bool: bool,
        product_category: product_category,
      });
    },
    deleteProductCategory(bool, product_category) {
      this.$store.commit("product_categories/DELETE_MODAL", {
        bool: bool,
        product_category: product_category,
      });
    },

    editProductCategory(product_category_id) {
      this.$store.dispatch(
        "product_categories/editProductCategory",
        product_category_id
      );
      this.$router.push({ name: "Edit Product Category" });
    },
  },
};
</script>
